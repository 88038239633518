<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">
      <div class="container">
        <div class="columns">
          <div class="column is-12-desktop">
            <h1 class="title is-2 is-spaced">
              Report Builder
            </h1>
          </div>
        </div>
        <button @click.prevent="email()">Test</button>
      </div>
    </section>
    <pre>{{ output }}</pre>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-6-desktop">
            <div v-if="hasComments" >
              <select v-model="commentBlock">
                <option>Choose a comment block</option>
                <option v-for="option in Object.keys(comments)" :value="option" :key="option">
                  {{ option }}
                </option>
              </select>
              <div class="buttons has-addons">
                <button class="button" :class="(gender=='male' ? 'is-success is-selected' : '')" @click="gender='male'">Male</button>
                <button class="button" :class="(gender=='female' ? 'is-success is-selected' : '')" @click="gender='female'">Female</button>
                <button class="button" :class="(gender=='n' ? 'is-success is-selected' : '')" @click="gender='n'">Neutral</button>
              </div>


              <table v-if="canChooseComments" class="table" ref="selectionX">
                <tr v-for="sent,index in chosenComments.comments" :key="index">
                  <td v-html="sent"></td>
                </tr>
              </table>

            </div>
            <h2 v-else>Load Comments</h2>
          </div>
          <div class="column is-6-desktop">
            <textarea class="textarea" placeholder="Choose from your starters on the left" rows="10"></textarea>
          </div>
        </div>
      </div>

    </section>



    <textarea v-model="base"></textarea>
    <button @click="processCommentBank()">Process Comment Bank</button>
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';

//import { toast } from "bulma-toast";
//import { format } from "date-fns";
//import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
//import download from "downloadjs";


export default {
  name: "home",



  data: function () {
    return {
      base: "",
      comments: {},
      output: "",
      lists: {},
      commentBlock: "x",
      chosenComments: {},
      optionsChosen: {},
      renderableLists: {},
      name: "Jack",
      gender: "female",
      replacements: {
        "#e": { male: "he", female: "she", n: "they"},
        "#E": { male: "He", female: "She", n: "They"},
        "#H": { male: "His", female: "Her", n: "Their"},
        "#h": { male: "his", female: "her", n: "their"},
        "#b": { male: "boy", female: "girl", n: "child"},
        "#m": { male: "him", female: "her", n: "them"},
        "#s": { male: "himself", female: "herself", n: "themself"},



/*    #? - be prompted for text later e.g. #N achieved #?% in the exam. */

      }
    };
  },

  computed: {
    hasComments() {
      return Object.keys(this.comments ?? []).length > 0
    },

    canChooseComments() {
        return (this.commentBlock !== "x")
    }
  },


  methods: {

    async email() {
      let response = await this.$api.get("v1/xe/", true, true);
      console.log(response)
      this.output = response
    },

    handleClick(e) {

      console.log(e.target.dataset)

    },
    updateCommentsAndLists(a) {

      console.log(["I am here", a])



      let nL = {}

      Object.keys(this.lists).forEach(x => {
        nL[x]=JSON.parse(JSON.stringify(this.lists[x])).map(y => this.expandString(y))
      })

      this.renderableLists = nL
      console.log(this.renderableLists)

      a = JSON.parse(JSON.stringify(a))
      console.log([a, "woooo"])
      a.comments = a.comments.map((q, index) => this.expandString(q, index));
      return a;
    },
    expandString(x, index = false) {

        if (x.indexOf("#") === -1) {
          return x;
        }

        x = x.replaceAll(/#N\b/g, "<span class='has-background-danger-light'>"+this.name+"</span>");

        Object.keys(this.replacements).forEach(a => {
          let b = this.replacements[a]["regex"] ?? null;
          if (b === null) {
            b = new RegExp(a + '\\b', 'g')
            this.replacements[a]["regex"] = b
          }
          x = x.replaceAll( b, "<span class='has-background-success-light'>"+this.replacements[a][this.gender]+"</span>");
        })

        if (index === false) {
          return x
        }


        Object.keys(this.renderableLists).forEach(a => {

            let b = new RegExp("#"+ a + '\\b')
            let content = null

            if (this.optionsChosen[this.commentBlock] && this.optionsChosen[this.commentBlock][index] && this.optionsChosen[this.commentBlock][index][a]) {
              content = this.optionsChosen[this.commentBlock][index][a]
            }
            else {
              content = a
            }

            let subs = `<div class="dropdown">
                          <div class="dropdown-trigger">
                            <button class="is-small has-background-info-light" aria-haspopup="true" aria-controls="dropdown-menu">

                            <span class='selectorbutton'>`+content+`</span>
                              <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                              </span>
                            </button>
                          </div>
                          <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">`;

      this.renderableLists[a].forEach(c => {

        subs += '<a href="#" data-list="'+a+'" data-value="'+c+'" data-index="'+index+'" class="dropdown-item">'+c+'</a>'

      })




      subs = subs + "</div></div></div>"

            x = x.replace( b, subs)



        })

        return x


    },

    processCommentBank() {

      this.optionsChosen = {}

      let x = this.base.split(/\r?\n/).filter(l => {
        l = l.replace(/\/\*.*$/, "").trim()
        return (l.length !== 0)
      })

      let f = x.reduce((prev, curr) => {

        if (prev.mode === "COMMENTS") {
          if (curr.substr(0,9).toUpperCase() != "COMMENTS:" && curr.substr(0,5).toUpperCase() != "LIST:") {

            let a = curr.replace(/^\d+=/, "").trim()

            prev.comments[prev.key].comments.push(a)
            return prev;
          }
          prev.mode = undefined
        }
        else if (prev.mode === "LIST") {
          if (curr.substr(0,9).toUpperCase() != "COMMENTS:" && curr.substr(0,5).toUpperCase() != "LIST:") {
            prev.lists[prev.key].push(curr.replace(/^\d+=/, "").trim())
            return prev;
          }
          prev.mode = undefined
        }

        if (prev.mode === undefined) {
          if (curr.substr(0,9).toUpperCase() == "COMMENTS:") {
            prev.mode = "COMMENTS"

            let y = curr.substr(9).trim().split("#")



            prev.key = y[0]
            if (!prev.comments) {
              prev.comments = {}
            }

            let number = 1
            if (y[1]) {
              number = parseInt(y[1])
            }
            prev.comments[prev.key] = { number: number, comments: [] }
            return prev;
          }

          if (curr.substr(0,5).toUpperCase() == "LIST:") {
            prev.mode = "LIST"
            prev.key = curr.substr(5).trim()
            if (!prev.lists) {
              prev.lists = {}
            }
            prev.lists[prev.key] = []
            return prev;
          }

          console.log(["what?", curr])
        }

    }, { mode: undefined }) ;

    this.comments = f.comments
    this.lists = f.lists

    },

    closeDropdowns() {
      const dropdowns = document.getElementsByClassName('dropdown')
      if (dropdowns.length ===0) {
        return
      }
      console.log([dropdowns, dropdowns.length])
      for (let el of dropdowns) {
        el.classList.remove('is-active');
      }
    },

    updateDropdowns() {
            const dropdowns = document.querySelectorAll('.dropdown:not(.is-hoverable)');



      if (dropdowns.length > 0) {
        // For each dropdown, add event handler to open on click.

        dropdowns.forEach(function(el) {
          el.addEventListener('click', function(e) {
            e.stopPropagation();
            el.classList.toggle('is-active');
          });
        });




        // If user clicks outside dropdown, close it.
        document.addEventListener('click', () =>
          this.closeDropdowns()
        );

        const dropdownsItems = document.querySelectorAll('.dropdown-item');

        dropdownsItems.forEach((el) => {

          el.addEventListener('click', (e) => {

            console.log(e);


            if (!this.optionsChosen[this.commentBlock]) {
              this.optionsChosen[this.commentBlock]={}
            }

            if (!this.optionsChosen[this.commentBlock][e.target.dataset.index]) {
              this.optionsChosen[this.commentBlock][e.target.dataset.index]={}
            }

            this.optionsChosen[this.commentBlock][e.target.dataset.index][e.target.dataset.list] = e.target.dataset.value
            console.log(e.target.parentElement.parentElement.parentElement);

            let a=e.target.parentElement.parentElement.parentElement

            let x = a.querySelectorAll('button > span.selectorbutton').item(0)
            console.log(x)
            x.innerHTML = e.target.dataset.value

            e.stopPropagation();
            this.closeDropdowns()

          });
        });



      }
    }




  },
  watch: {
    comments() {
      this.commentBlock = "x"
    },
    async commentBlock(newData) {
      if (this.commentBlock === "x") {
        this.chosenComments = []
        return
      }
      this.chosenComments = this.updateCommentsAndLists(this.comments[newData])
      await this.$nextTick()
      this.updateDropdowns()

    },
    async gender() {
      console.log(this.gender)
      if (this.commentBlock === "x") {
        this.chosenComments = [];
        return
      }
      this.chosenComments = this.updateCommentsAndLists(this.comments[this.commentBlock])
      await this.$nextTick()
      this.updateDropdowns()
    },




  }

};
</script>
